import React, { useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Layout from "../components/Layout";
import "../css/card.css";
import Wallets from "../components/Wallets";
import OrderBooks from "../components/OrderBooks";
import WebSocketChat from "../components/WebsocketChat";
import DocumentUploader from "../components/DocumentUploader";
import ToolList from "../components/ToolList";

function Home() {
  return (
    <Layout>
      <Row>
        <Col xs={12} md={6}>
          <WebSocketChat />
          <ToolList />
        </Col>
        <Col xs={12} md={6}>
          <DocumentUploader />
        </Col>
      </Row>
    </Layout>
  );
}

export default Home;
