import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useAuth } from "../context/AuthContext";

const DocumentUploader = () => {
  const [content, setContent] = useState("");
  const [customMetadata, setCustomMetadata] = useState([]);
  const [keyInput, setKeyInput] = useState("");
  const [valueInput, setValueInput] = useState("");
  const [category, setCategory] = useState([]);
  const [documents, setDocuments] = useState([]);
  const { authAxios } = useAuth();

  useEffect(() => {
    const fetchDocuments = async () => {
      const data = await fetchIndexedDocuments();
      setDocuments(data);
    };
    fetchDocuments();
  }, []);

  const fetchIndexedDocuments = async () => {
    try {
      const response = await authAxios.get("/product/api/indexed-documents/");
      return response.data;
    } catch (error) {
      console.error("Error fetching indexed documents:", error);
      return [];
    }
  };

  const handleAddCustomMetadata = () => {
    if (keyInput.trim() && valueInput.trim()) {
      setCustomMetadata((prev) => [
        ...prev,
        { key: keyInput, value: valueInput },
      ]);
      setKeyInput("");
      setValueInput("");
    } else {
      alert("Both key and value are required for custom metadata.");
    }
  };

  const handleDeleteDocument = async (docId) => {
    try {
      await authAxios.delete(`/product/api/indexed-documents/${docId}/`);
      setDocuments((prev) => prev.filter((doc) => doc.id !== docId));
    } catch (error) {
      console.error("Error deleting document:", error);
      alert("Failed to delete the document. Please try again.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!content.trim()) {
      alert("Content cannot be empty.");
      return;
    }

    const combinedMetadata = [
      ...defaultMetadata,
      ...customMetadata,
      ...category.map((opt) => ({ key: "category", value: opt.label })),
    ];

    const document = {
      content,
      metadata: Object.fromEntries(
        combinedMetadata.map((item) => [item.key, item.value])
      ),
    };

    try {
      const response = await authAxios.post(
        "/product/api/indexed-documents/",
        document
      );
      setDocuments([...documents, response.data]);
      setContent("");
      setCustomMetadata([]);
      setCategory([]);
    } catch (error) {
      console.error("Error uploading document:", error);
    }
  };

  const defaultMetadata = [
    { key: "protocol", value: "Pear Protocol" },
    { key: "category", value: "DeFi" },
  ];

  const categoryOptions = [
    { value: "trading", label: "Trading" },
    { value: "arbitrage", label: "Arbitrage" },
    { value: "staking", label: "Staking" },
    { value: "liquidity", label: "Liquidity" },
    { value: "pear protocol", label: "Pear Protocol" },
    { value: "defi", label: "DeFi" },
    { value: "pair trading", label: "Pair Trading" },
  ];

  return (
    <div
      style={{
        maxWidth: "100%",
        padding: "20px",
        fontSize: "16px",
        boxSizing: "border-box",
      }}
    >
      <h2>Upload Document for Semantic Search</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label style={{ display: "block", fontWeight: "bold" }}>
            Content:
          </label>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            rows="5"
            placeholder="Enter document content here..."
            style={{
              width: "100%",
              padding: "10px",
              marginBottom: "15px",
              borderRadius: "8px",
              backgroundColor: "#3c4047",
              color: "#CAD2C5",
              border: "1px solid #52796F",
            }}
          ></textarea>
        </div>

        <div>
          <label style={{ display: "block", fontWeight: "bold" }}>
            Category:
          </label>
          <Select
            options={categoryOptions}
            isMulti
            value={category}
            onChange={(selected) => setCategory(selected)}
            className="react-select-container"
            classNamePrefix="react-select"
            placeholder="Select categories..."
          />
        </div>

        <div style={{ marginTop: "15px" }}>
          <h4>Custom Metadata</h4>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            <input
              type="text"
              value={keyInput}
              onChange={(e) => setKeyInput(e.target.value)}
              placeholder="Key"
              style={{
                flex: "1 1 calc(50% - 10px)",
                padding: "10px",
                borderRadius: "8px",
                backgroundColor: "#3c4047",
                color: "#CAD2C5",
                border: "1px solid #52796F",
              }}
            />
            <input
              type="text"
              value={valueInput}
              onChange={(e) => setValueInput(e.target.value)}
              placeholder="Value"
              style={{
                flex: "1 1 calc(50% - 10px)",
                padding: "10px",
                borderRadius: "8px",
                backgroundColor: "#3c4047",
                color: "#CAD2C5",
                border: "1px solid #52796F",
              }}
            />
            <button
              type="button"
              onClick={handleAddCustomMetadata}
              style={{
                flex: "1 1 calc(100% - 10px)",
                backgroundColor: "#ABDF75",
                color: "#1C1C1C",
                padding: "10px",
                borderRadius: "8px",
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              Add
            </button>
          </div>
        </div>

        <button
          type="submit"
          style={{
            width: "100%",
            backgroundColor: "#ABDF75",
            color: "#1C1C1C",
            padding: "10px",
            borderRadius: "8px",
            cursor: "pointer",
            marginTop: "15px",
          }}
        >
          Upload Document
        </button>
      </form>

      <div style={{ marginTop: "30px" }}>
        <h2>Uploaded Documents</h2>
        {documents.length === 0 ? (
          <p>No documents uploaded yet.</p>
        ) : (
          <div style={{ overflowX: "auto", marginTop: "15px" }}>
            <table
              style={{
                minWidth: "600px", // Ensures table doesn't shrink too small
                borderCollapse: "collapse",
                backgroundColor: "#1C1C1C",
                color: "#ACDF75",
                width: "100%", // Allows flexibility within the scroll container
              }}
            >
              <thead>
                <tr>
                  <th style={{ padding: "10px", border: "1px solid #ACDF75" }}>
                    #
                  </th>
                  <th style={{ padding: "10px", border: "1px solid #ACDF75" }}>
                    Content
                  </th>
                  <th style={{ padding: "10px", border: "1px solid #ACDF75" }}>
                    Metadata
                  </th>
                  <th style={{ padding: "10px", border: "1px solid #ACDF75" }}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {documents.map((doc, index) => (
                  <tr
                    key={doc.id}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#121212" : "#1C1C1C",
                    }}
                  >
                    <td
                      style={{
                        padding: "10px",
                        border: "1px solid #ACDF75",
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{ padding: "10px", border: "1px solid #ACDF75" }}
                    >
                      {doc.content.slice(0, 100)}...
                    </td>
                    <td
                      style={{ padding: "10px", border: "1px solid #ACDF75" }}
                    >
                      <ul
                        style={{ padding: "0", margin: "0", listStyle: "none" }}
                      >
                        {doc.metadata &&
                          Object.entries(doc.metadata).map(
                            ([key, value], idx) => (
                              <li key={idx}>
                                <strong>{key}:</strong> {value}
                              </li>
                            )
                          )}
                      </ul>
                    </td>

                    <td
                      style={{ padding: "10px", border: "1px solid #ACDF75" }}
                    >
                      <button
                        onClick={() => handleDeleteDocument(doc.id)}
                        style={{
                          backgroundColor: "#FF6B6B",
                          color: "white",
                          padding: "5px 10px",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentUploader;