import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import Layout from "../components/Layout";
import "../css/card.css";
import { useParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const OrderBook = () => {
  const { id } = useParams();
  const { authAxios } = useAuth();
  const [orderBook, setOrderBook] = useState(null);
  const [wallets, setWallets] = useState([]);
  const [configs, setConfigs] = useState([]);

  useEffect(() => {
    const fetchOrderBook = async () => {
      try {
        const response = await authAxios.get(
          `${process.env.REACT_APP_API_URL}/product/orderbook/${id}/`
        );
        setOrderBook(response.data);
      } catch (error) {
        console.error("Error fetching order book:", error);
      }
    };

    const fetchWallets = async () => {
      try {
        const response = await authAxios.get(
          `${process.env.REACT_APP_API_URL}/product/wallet/`
        );
        setWallets(response.data);
      } catch (error) {
        console.error("Error fetching wallets:", error);
      }
    };

    const fetchConfigs = async () => {
      try {
        const response = await authAxios.get(
          `${process.env.REACT_APP_API_URL}/product/config/`
        );
        setConfigs(response.data);
      } catch (error) {
        console.error("Error fetching configs:", error);
      }
    };

    fetchOrderBook();
    fetchWallets();
    fetchConfigs();
  }, [id, authAxios]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrderBook((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setOrderBook((prev) => ({ ...prev, [name]: parseInt(value) }));
  };

  const handleSave = async () => {
    try {
      await authAxios.put(
        `${process.env.REACT_APP_API_URL}/product/orderbook/${id}/`,
        orderBook
      );
      alert("Order book updated successfully!");
    } catch (error) {
      console.error("Error updating order book:", error);
      alert("Failed to update order book.");
    }
  };

  const toggleRunning = async () => {
    const updatedOrderBook = { ...orderBook, is_running: !orderBook.is_running };
    try {
      await authAxios.put(
        `${process.env.REACT_APP_API_URL}/product/orderbook/${id}/`,
        updatedOrderBook
      );
      setOrderBook(updatedOrderBook);
    } catch (error) {
      console.error("Error updating running status:", error);
    }
  };

  if (!orderBook) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <Card bg="dark">
        <Card.Header>
          <h3>Order Book {orderBook.id}</h3>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row className="mb-3">
              <Col xs={12} md={6}>
                <Form.Group controlId="formBase">
                  <Form.Label>Base</Form.Label>
                  <Form.Control
                    type="text"
                    name="base"
                    value={orderBook.base}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="formQuote">
                  <Form.Label>Quote</Form.Label>
                  <Form.Control
                    type="text"
                    name="quote"
                    value={orderBook.quote}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={6}>
                <Form.Group controlId="formWallet">
                  <Form.Label>Wallet</Form.Label>
                  <Form.Control
                    as="select"
                    name="wallet"
                    value={orderBook.wallet || ""}
                    onChange={handleSelectChange}
                  >
                    <option value="">Select Wallet</option>
                    {wallets.map((wallet) => (
                      <option key={wallet.id} value={wallet.id}>
                        {wallet.address}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="formConfig">
                  <Form.Label>Config</Form.Label>
                  <Form.Control
                    as="select"
                    name="config"
                    value={orderBook.config || ""}
                    onChange={handleSelectChange}
                  >
                    <option value="">Select Config</option>
                    {configs.map((config) => (
                      <option key={config.id} value={config.id}>
                        {config.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="formConfig">
                  <Form.Label>Running:</Form.Label>
                  {orderBook.is_running ? " ✅" : " ❌"}
                </Form.Group>

              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Button className="m-0" variant="primary" onClick={handleSave}>
                  Save
                </Button>
                <Button
                  variant={orderBook.is_running ? "danger" : "success"}
                  onClick={toggleRunning}
                  className="m-0 ms-2"
                >
                  {orderBook.is_running ? "Stop" : "Run"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Layout>
  );
};

export default OrderBook;
