import React, { createContext, useContext, useState } from "react";
import axios from "axios";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const baseURL = process.env.REACT_APP_API_URL;
  console.log(baseURL)
  const [authToken, setAuthToken] = useState(() => localStorage.getItem("auth_token"));

  const logout = () => {
    setAuthToken(null);
    localStorage.removeItem("auth_token");
    axios.post(`${baseURL}/dj-rest-auth/logout/`).then(() => {
      window.location.reload();
    });
  };

  const authAxios = axios.create({
    baseURL: baseURL,
  });

  authAxios.interceptors.request.use(
    (config) => {
      if (authToken) {
        config.headers.Authorization = `Token ${authToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  const login = async (email, password) => {
    try {
      const response = await axios.post(`${baseURL}/dj-rest-auth/login/`, {
        email,
        password,
      });
      const token = response.data.key; // Assuming 'key' is the token
      setAuthToken(token);
      localStorage.setItem("auth_token", token);
      window.location.href = '/'; // Navigate to home page
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const register = async (email, password1, password2) => {
    try {
      const response = await axios.post(`${baseURL}/dj-rest-auth/registration/`, {
        email: email,
        password1: password1,
        password2: password2,
      });
      console.log('Registration successful: ' + response.data.detail);
    } catch (error) {
      console.error('Registration failed:', error);
      throw error;
    }
  };

  const verifyEmail = async (key) => {
    try {
      const response = await axios.post(`${baseURL}/dj-rest-auth/registration/verify-email/`, { key });
      console.log('Email verification successful: ' + response.data.detail);
      return response.data.detail;
    } catch (error) {
      console.error('Email verification failed:', error);
      throw error;
    }
  };

  let projectID = 1
  return (
    <AuthContext.Provider
      value={{
        authAxios,
        authToken,
        setAuthToken,
        login,
        logout,
        register,
        verifyEmail,
        projectID,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
