import React from "react";
import { Badge, Container } from "react-bootstrap";

const tools = [
  "Coin Categories", // Retrieve a list of all cryptocurrency categories.
  "Coins by Category Name", // Fetch coins that belong to a specific category.
  "Historical Fear and Greed Index", // Access historical Fear and Greed Index data.
  "Top Coins by Market Cap", // List the top cryptocurrencies based on market capitalization.
  "OHLCV", // Fetch Open, High, Low, Close, and Volume data for a cryptocurrency.
  "Rolling Volatility", // Calculate rolling volatility for a cryptocurrency.
  "RSI", // Fetch the Relative Strength Index for a cryptocurrency.
  "Highest Movers", // Identify cryptocurrencies with the highest price changes.
  "Highest Correlated", // Find cryptocurrencies with the highest price correlation.
  "Highest Cointegrated", // Fetch cryptocurrencies that are most statistically cointegrated.
  "Supported Exchange Pairs", // List all exchange pairs supported by the platform.
  "Supported Coins", // Retrieve a list of all supported cryptocurrencies.
  "Funding Rate OHLC History", // Access historical funding rates in OHLC format.
  "Aggregated Liquidation History", // View aggregated liquidation data across exchanges.
  "Coinbase Premium", // Retrieve the Coinbase Premium price difference.
  "Coins by Average Funding Rate", // List cryptocurrencies by average funding rates.
  "Highly Correlated With", // Find coins highly correlated with a given cryptocurrency.
  "Best Shorts for Long", // Identify the best cryptocurrencies to short for a given long.
  "Best Longs for Short", // Identify the best cryptocurrencies to long for a given short.
  "Semantic Search", // Perform a semantic search on the knowledge base.
];

const ToolList = () => {
  return (
    <Container className="my-3">
        <h5>Available Tools</h5>
      {tools.map((tool, index) => (
        <Badge key={index} pill variant="primary" className="m-1">
          {tool}
        </Badge>
      ))}
    </Container>
  );
};

export default ToolList;
